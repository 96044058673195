<template>
  <div class="card-content">
    <v-card-actions class="ma-0 pa-0">
      <v-card-subtitle
        v-if="fullscreen && !$vuetify.breakpoint.xs"
        data-cy="historyGraphLabel"
        >{{ graphLabel }}</v-card-subtitle
      >
      <v-spacer></v-spacer>
      <div class="timePeriodControl" ref="timePeriodControl">
        <span class="timePeriodControl__label">
          {{ $t('map.historyDialog.timePeriodLabel') }}
        </span>
        <div class="timePeriodControl__controls">
          <v-btn
            :color="getButtonColor('day')"
            depressed
            small
            @click="changeTimePeriod('day')"
            data-cy="historyTimePeriodDay"
            :disabled="isLoading"
            v-if="allowDay"
            >{{ $t('map.historyDialog.timePeriod.day') }}</v-btn
          >
          <v-tooltip bottom :disabled="hasWeeklyPermission">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :color="getButtonColor('week')"
                depressed
                small
                @click="changeTimePeriod('week')"
                data-cy="historyTimePeriodWeek"
                :disabled="isLoading"
                v-if="allowWeek"
                :class="[{ restrictedContent: !hasWeeklyPermission }]"
                v-bind="attrs"
                v-on="on"
                >{{ $t('map.historyDialog.timePeriod.week') }}</v-btn
              >
            </template>
            <span>{{ getPermissionTooltip() }}</span>
          </v-tooltip>
          <v-tooltip bottom :disabled="hasMonthlyPermission">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :color="getButtonColor('month')"
                depressed
                small
                @click="changeTimePeriod('month')"
                data-cy="historyTimePeriodMonth"
                :disabled="isLoading"
                v-if="allowMonth"
                :class="[{ restrictedContent: !hasMonthlyPermission }]"
                v-bind="attrs"
                v-on="on"
                >{{ $t('map.historyDialog.timePeriod.month') }}</v-btn
              >
            </template>
            <span>{{ getPermissionTooltip() }}</span>
          </v-tooltip>
          <v-tooltip bottom :disabled="hasThreeMonthsPermission">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :color="getButtonColor('threeMonths')"
                depressed
                small
                @click="changeTimePeriod('threeMonths')"
                data-cy="historyTimePeriodThreeMonths"
                :disabled="isLoading"
                v-if="allowThreeMonths"
                :class="[{ restrictedContent: !hasThreeMonthsPermission }]"
                v-bind="attrs"
                v-on="on"
                >{{ $t('map.historyDialog.timePeriod.threeMonths') }}</v-btn
              >
            </template>
            <span>{{ getPermissionTooltip() }}</span>
          </v-tooltip>
          <v-btn
            :color="getButtonColor('custom')"
            depressed
            small
            data-cy="historyTimePeriodCustom"
            :disabled="isLoading"
            v-if="showCustomButton"
            @click="$emit('selectCustomDateRange')"
          >
            <v-icon>mdi-calendar</v-icon>
          </v-btn>
        </div>
      </div>
    </v-card-actions>
    <v-card-subtitle
      v-if="!fullscreen || $vuetify.breakpoint.xs"
      ref="historyGraphLabel"
      data-cy="historyGraphLabel"
      >{{ graphLabel }}
    </v-card-subtitle>
    <div
      :style="`height: ${graphHeight}; width: ${graphWidth};`"
      class="graph"
      :class="{ 'opacity-20': isLoading }"
      ref="graph"
      data-cy="historyGraph"
    ></div>
    <div
      v-if="isLoading || redrawGraph"
      class="loading-container"
      :class="{
        'loading-container-fullscreen': fullscreen,
      }"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div
      v-if="numShownDatasets < 1 && !isLoading && graphDrawn"
      class="empty-message"
      data-cy="historyGraphEmptyMessage"
      :class="{
        'empty-message-fullscreen': fullscreen && !$vuetify.breakpoint.xs,
      }"
    >
      {{ $t('map.historyDialog.graph.emptyMessage') }}
    </div>
    <v-row
      v-if="selectedDevices.length > 0"
      class="selected-devices"
      :class="{ 'opacity-20': isLoading }"
      ref="selectedDevices"
    >
      <div
        v-for="(device, index) in selectedDevices"
        :key="device.id"
        :class="{ inactive: !isDeviceActive(device) }"
        :style="`border-left: 4px solid ${graphColors[index]};`"
        :data-cy="`historyDialogDeviceName${index}`"
      >
        <v-tooltip
          bottom
          max-width="600"
          :disabled="disableDeviceTooltip(device)"
        >
          <template v-slot:activator="{ on }">
            <v-chip color="white" label small v-on="on">
              <img
                v-if="isDeviceIndoor(device.id)"
                class="selected-device-icon"
                :src="indoorIconSrc(device.model)"
                alt="Indoor Device Icon"
              />
              <img
                v-else
                class="selected-device-icon"
                :src="outdoorIconSrc(device.model)"
                alt="Outdoor Device Icon"
              />
              <span
                v-if="device.friendlyName"
                class="device-name"
                :ref="device.id"
              >
                <span>{{ device.friendlyName }}</span>
              </span>
              <span v-else class="device-name">
                {{ $t('map.popover.publicDevice') }} {{ index + 1 }}
              </span>
              <v-icon
                v-if="removableDevices"
                @click="removeDevice(index)"
                right
              >
                mdi-close
              </v-icon>
            </v-chip>
          </template>
          <span>{{ getDeviceTooltip(device) }}</span>
        </v-tooltip>
      </div>
    </v-row>
    <div
      v-if="selectedDevices.length === 5"
      class="maximum-met-message"
      ref="maximumMetMessage"
    >
      {{ $t('map.historyDialog.maximumMet') }}
    </div>
  </div>
</template>

<script>
import Highcharts from 'highcharts/highstock'
import boost from 'highcharts/modules/boost'
boost(Highcharts)

import ExportDataDialog from '../dialogs/ExportDataDialog.vue'
import {
  defaultGraphOptions,
  defaultGraphColors,
  getTooltipFormatterCallback,
  createFlags,
  findGaps,
  getPlotLines,
  getCustomTooltipPosition,
  renderNoTimePeriodTooltip,
} from '../../services/graphing'
import { HTTPStatus } from '../../api'
import {
  measurementTypes,
  measurementTypesKey,
  sensorTypesKey,
  measurementTypesLabelKey,
  measurementTypesUnitsKey,
  measurementPermissions,
} from '../../services/device-measurements'
import { SlugsEnum } from '../../permissions/SlugsEnum'
import { featureFlags } from '../../services/feature-flags'
import { DEVICE_UPDATE_INTERVAL_MS } from '../../services/device-metadata'
import { isChildOverflowing } from '../../services/html-utilities'
import { UnitOfMeasure, UnitOfMeasureProperties } from '@fusion/units'

Vue.component('export-data-dialog', ExportDataDialog)

const GRAPH_WIDTH = 'calc(100%+30px)'
const GRAPH_HEIGHT_SMALL = '278px'
const GRAPH_HEIGHT_MEDIUM = '400px'
const GRAPH_HEIGHT_FULLSCREEN = '600px'
const DIALOG_TITLE_HEIGHT = 36
const WIDGET_TITLE_HEIGHT = 42
const DEFAULT_ELEMENT_HEIGHT = 0
const TIME_PERIOD_DAY = 'day'
const TIME_PERIOD_WEEK = 'week'
const TIME_PERIOD_MONTH = 'month'
const TIME_PERIOD_THREE_MONTHS = 'threeMonths'
const TIME_PERIOD_CUSTOM = 'custom'
const VALID_EXTREMES = ['min', 'max']

export default {
  name: 'HistoryGraph',
  props: {
    allowDay: { type: Boolean, default: true },
    allowWeek: { type: Boolean, default: true },
    allowMonth: { type: Boolean, default: true },
    allowThreeMonths: { type: Boolean, default: true },
    allowCustom: { type: Boolean, default: true },
    customDateRangeModel: Array,
    open: Boolean,
    isFullscreen: Boolean,
    isDetachedDialog: Boolean,
    removableDevices: Boolean,
    selectedDevices: Array,
    selectedParameter: Number,
    selectedTimePeriod: String,
    accountId: String,
    width: Number,
    height: Number,
    permissions: Object,
  },
  data() {
    return {
      isLoading: false,
      fullscreen: false,
      timePeriod: TIME_PERIOD_DAY,
      customDateRange: { start: null, end: null },
      precision: 0,
      graph: null,
      graphWidth: GRAPH_WIDTH,
      graphHeight: GRAPH_HEIGHT_MEDIUM,
      graphOptions: defaultGraphOptions,
      graphColors: defaultGraphColors,
      numShownDatasets: 0,
      graphDrawn: false,
      graphInit: false,
      graphRange: {
        min: null,
        max: null,
      },
      unwatch: null,
      loadDataInterval: null,
      dialogs: {
        exportData: false,
      },
      redrawGraph: true, // when true, graph load should be delayed and redrawn, patch for cursor bug,
      loadDataQueue: [],
      historicalData: [],
      eventListeners: [], // Store references for removing event listeners upon scrolling/changing fullscreen
    }
  },
  mounted() {
    this.unwatch = this.$store.watch(
      (state) => {
        return state.userProfile.settings.tempUnit
      },
      () => {
        this.close()
      }
    )
  },
  updated() {
    this.setGraphHeight()
  },
  destroyed() {
    this.unwatch()
    this.stopLoadDataInterval()
  },
  watch: {
    open() {
      if (!this.open) {
        // on close
        this.redrawGraph = true
        if (this.graph) {
          this.destroyGraph()
        }
        this.stopLoadDataInterval()
        this.timePeriod = TIME_PERIOD_DAY
        this.cleanHistoricalData()
      } else {
        Vue.nextTick(() => {
          this.loadDataHandler()
        })
        this.startLoadDataInterval()
      }
    },
    width() {
      this.setGraphHeight()
      this.reflowGraph()
    },
    height() {
      this.setGraphHeight()
      this.reflowGraph()
    },
    isFullscreen() {
      this.setGraphHeight()
      this.reflowGraph()
      this.removeExistingEventListeners()
      this.addEventListeners()
    },
    selectedParameter() {
      Vue.nextTick(() => {
        this.cleanHistoricalData()
        this.loadDataHandler()
      })
    },
    selectedDevices() {
      Vue.nextTick(() => {
        //if the selected devices change we have to update time period if more restrictive
        if (
          (this.timePeriod === TIME_PERIOD_WEEK && !this.hasWeeklyPermission) ||
          (this.timePeriod === TIME_PERIOD_MONTH && !this.hasMonthlyPermission) ||
          (this.timePeriod === TIME_PERIOD_THREE_MONTHS && !this.hasThreeMonthsPermission) &&
          (this.hasDailyPermission)
        ) {
          this.changeTimePeriod(TIME_PERIOD_DAY)
        } else {
          this.cleanHistoricalData()
          this.loadDataHandler()
          this.stopLoadDataInterval()
          this.startLoadDataInterval()
        }
      })
    },
    selectedTimePeriod(newValue) {
      this.timePeriod = newValue
    },
    async 'loadDataQueue.length'() {
      if (!this.isLoading && this.loadDataQueue.length) {
        await this.loadDataQueue[0]()
        this.loadDataQueue = this.loadDataQueue.slice(1)
      }
    }
  },
  computed: {
    hasRegisteredDevice: function () {
      if (Object.keys(this.$store.state.devices.name).length === 0) {
        return false
      }
      for (const key in this.$store.state.devices.name) {
        //isOwned
        if (this.accountId === this.$store.state.devices.accountId[key]) {
          return true
        }
      }
      return false
    },
    displayMyDevice: function () {
      for (let i = 0; i < this.selectedDevices.length; i++) {
        if (
          this.isDeviceMine(this.selectedDevices[i].id)
        ) {
          return true
        }
      }
      return false
    },
    displayPublicDevice: function () {
      for (let i = 0; i < this.selectedDevices.length; i++) {
        if (
          this.isDevicePublic(this.selectedDevices[i].id)
        ) {
          return true
        }
      }
      return false
    },
    displayNonPublicDevice: function () {
      for (let i = 0; i < this.selectedDevices.length; i++) {
        if (
          !this.isDevicePublic(this.selectedDevices[i].id)
        ) {
          return true
        }
      }
      return false
    },
    hasDailyPermission: function () {
      return (
        this.isAllowedToExecuteHistoryChartThreeMonths ||
        this.isAllowedToExecuteHistoryChartMonth ||
        this.isAllowedToExecuteHistoryChartDay ||
        this.displayPublicDevice
      )
    },
    hasWeeklyPermission: function () {
      //has permission slug for some time period longer than a week
      return (
        this.isAllowedToExecuteHistoryChartThreeMonths ||
        this.isAllowedToExecuteHistoryChartMonth
      )
    },
    hasMonthlyPermission: function () {
      return (
        this.isAllowedToExecuteHistoryChartThreeMonths ||
        this.isAllowedToExecuteHistoryChartMonth
      )
    },
    hasThreeMonthsPermission: function () {
      return this.isAllowedToExecuteHistoryChartThreeMonths
    },
    showCustomButton: function () {
      const customDatesFeatureFlagEnabled = this.$store.getters['featureFlags/getFeatureFlagBySlug'](
        featureFlags.HistoryChartCustomDateRange
      )
      return this.allowCustom && customDatesFeatureFlagEnabled
    },
    timePeriodControl: function () {
      const periods = {
        day: false,
        week: false,
        month: false,
        threeMonths: false,
        custom: false,
      }
      periods[this.timePeriod] = true
      return periods
    },
    measurementType: function () {
      return this.$store.getters['map/getMeasurementType']
    },
    graphLabel: function () {
      return measurementTypesLabelKey[this.selectedParameter]
        ? this.$t(
            `map.historyDialog.dropdown.${
              measurementTypesLabelKey[this.selectedParameter]
            }`
          )
        : ''
    },
    currentReadingType: function () {
      let currReadingType = 'pm25'
      switch (this.measurementType) {
        case measurementTypes.pm25:
          currReadingType = 'pm25'
          break
        case measurementTypes.pm10:
          currReadingType = 'pm10'
          break
      }
      return currReadingType
    },
    sensors() {
      return this.$store.getters['devicemodels/getAlertableMeasurements'](
        this.device.model,
        this.device.submodel
      )
    },
    permissionSet() {
      return [
        ...new Set(
          this.selectedDevices
            .map((device) => this.permissions[device.id])
            .flat(1)
        ),
      ]
    },
    isAllowedToExecuteHistoryChartDay() {
      return this.permissionSet.includes(SlugsEnum.HistoryChartExecuteDataDay)
    },
    isAllowedToExecuteHistoryChartMonth() {
      return this.permissionSet.includes(SlugsEnum.HistoryChartExecuteDataMonth)
    },
    isAllowedToExecuteHistoryChartThreeMonths() {
      return this.permissionSet.includes(SlugsEnum.HistoryChartExecuteDataThreeMonths)
    },
    enableDataGaps() {
      return this.$store.getters['featureFlags/getFeatureFlagBySlug'](
        featureFlags.EnableHistoryChartDataGaps
      )
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    startLoadDataInterval() {
      if (this.loadDataInterval === null) {
        this.loadDataInterval = setInterval(
          this.loadDataHandler,
          DEVICE_UPDATE_INTERVAL_MS
        )
      }
    },
    stopLoadDataInterval: function () {
      if (this.loadDataInterval !== null) {
        clearInterval(this.loadDataInterval)
        this.loadDataInterval = null
      }
    },
    isDeviceIndoor(deviceId) {
      return this.$store.getters['devices/getIsIndoorByDeviceId'](deviceId)
    },
    isDeviceMine(deviceId) {
      return (
        this.accountId ===
        this.$store.getters['devices/getAccountByDeviceId'](deviceId)
      )
    },
    isDevicePublic(deviceId) {
      return this.$store.getters['devices/getIsPublicByDeviceId'](deviceId)
    },
    isMeasurementAllowedAndSupported(device) {
      return (
        this.isMeasurementSupported(device) && this.isMeasurementAllowed(device)
      )
    },
    isMeasurementAllowed(device) {
      if (device) {
        const slug = measurementPermissions[this.selectedParameter]
        if (slug) {
          return this.permissions[device.id].includes(slug)
        }
        return true
      }
      return false
    },
    isMeasurementSupported(device) {
      if (device) {
        const isPublicOnly = !device.isMine && !device.isShared
        const supportedMeasurements = this.$store.getters[
          'devicemodels/getSupportedMeasurements'
        ](device.model, device.submodel, isPublicOnly)
        return supportedMeasurements.includes(this.selectedParameter)
      }
      return false
    },
    isTimePeriodAllowed(device) {
      if (device) {
        if (this.timePeriod === TIME_PERIOD_DAY) {
          return this.isDayAllowed(device)
        }
        if (this.timePeriod === TIME_PERIOD_WEEK) {
          return this.isWeekAllowed(device)
        }
        if (this.timePeriod === TIME_PERIOD_MONTH) {
          return this.isMonthAllowed(device)
        }
        if (this.timePeriod === TIME_PERIOD_THREE_MONTHS) {
          return this.isThreeMonthsAllowed(device)
        }
        if (this.timePeriod === TIME_PERIOD_CUSTOM) {
          return true
        }
      }
      return false
    },
    isDayAllowed(device) {
      if (device) {
        return (
          this.permissions[device.id].includes(
            SlugsEnum.HistoryChartExecuteDataThreeMonths
          ) ||
          this.permissions[device.id].includes(
            SlugsEnum.HistoryChartExecuteDataMonth
          ) ||
          this.permissions[device.id].includes(
            SlugsEnum.HistoryChartExecuteDataDay
          ) ||
          this.isDevicePublic(device.id)
        )
      }
      return false
    },
    isWeekAllowed(device) {
      if (device) {
        return (
          this.permissions[device.id].includes(
            SlugsEnum.HistoryChartExecuteDataThreeMonths
          ) ||
          this.permissions[device.id].includes(
            SlugsEnum.HistoryChartExecuteDataMonth
          ) ||
          this.isDevicePublic(device.id)
        )
      }
      return false
    },
    isMonthAllowed(device) {
      if (device) {
        return (
          this.permissions[device.id].includes(
            SlugsEnum.HistoryChartExecuteDataThreeMonths
          ) ||
          this.permissions[device.id].includes(
            SlugsEnum.HistoryChartExecuteDataMonth
          )
        )
      }
      return false
    },
    isThreeMonthsAllowed(device) {
      if (device) {
        return this.permissions[device.id].includes(
          SlugsEnum.HistoryChartExecuteDataThreeMonths
        )
      }
      return false
    },
    removeDevice(index, options) {
      this.$emit('removeDevice', index, options)
      if (this.selectedDevices.length === 0) {
        this.close()
      }
    },
    changeTimePeriod(period) {
      if (
        period === TIME_PERIOD_DAY ||
        (period === TIME_PERIOD_WEEK && this.hasWeeklyPermission) ||
        (period === TIME_PERIOD_MONTH && this.hasMonthlyPermission) ||
        (period === TIME_PERIOD_THREE_MONTHS && this.hasThreeMonthsPermission) ||
        (period === TIME_PERIOD_CUSTOM)
      ) {
        this.timePeriod = period
        this.clearGraphRange()
        this.cleanHistoricalData()
        this.$emit('graphPeriodChanged', period)
        this.loadDataHandler()
      }
    },
    async loadData() {
      if (this.open) {
        this.isLoading = true
        this.numShownDatasets = 0
        this.graphDrawn = false
        this.clearPlotLines()
        this.destroyGraph()
        const measurementKey = this.selectedParameter

        if (!this.redrawGraph) {
          const requests = this.selectedDevices.map(async selectedDevice => {
            const supportedByDevice = this.isMeasurementAllowedAndSupported(
              selectedDevice
            )
            const timePeriodAllowedByDevice = this.isTimePeriodAllowed(
              selectedDevice
            )

            if (supportedByDevice && timePeriodAllowedByDevice) {
              let dataParams = this.prepareHistoricalDataParams(
                selectedDevice.id, measurementKey
              )
              await this.getHistoricalData(dataParams)
            }
          })
          await Promise.all(requests)
          this.applyGraphRange()
        } else {
          this.initGraph()
        }

        this.isLoading = false
        this.redrawGraph = false
        this.graphDrawn = true
      }
    },
    async getHistoricalData(dataParams) {
      try {
        const resp = await this.$api.getTelemetryGraphData(dataParams)
        if (resp.status === HTTPStatus.OK) {
          const body = await resp.json()
          const reading = body.data
          this.precision = body.precision ?? 0
          if (!this.graphInit) {
            this.initGraph()
          }

          let telemetry = []
          if (!this.deviceHasHistoricalData(dataParams.id) && reading.length > 0){
            this.historicalData.push({
              deviceId: dataParams.id,
              telemetryData: reading,
            })
          }
          else if (reading.length > 0) {
           this.appendDataToHistoryChart(dataParams.id, reading)
          }

          this.removeOutdatedDataFromHistoryChart(dataParams.id)
          telemetry = this.getHistoricalTelemetryData(dataParams.id)

          if (!telemetry?.length) {
          this.removeDeviceFromHistoricalData(dataParams.id)
          }

          await this.setGraphData(telemetry, dataParams.id)
        } else if (resp.status === HTTPStatus.NotFound) {
          const index = this.getIndexInSelectedDevices(dataParams.id)
          this.removeDevice(index, { force: true })
        }
      } catch (err) {
        //Notify user "not able to retrieve data" with error toast in future story
      }
    },
    prepareHistoricalDataParams(id, measurementKey) {
      let start = new Date()
      let end = this.getEndDate()
      if (!this.deviceHasHistoricalData(id)) {
        start = new Date(this.getStartDate())
      }
      else {
        let telemetry = this.getHistoricalTelemetryData(id)
        let latestTimestamp = telemetry[telemetry.length - 1][0]
        start = new Date(latestTimestamp)
        start.setSeconds(start.getSeconds() + 1)
      }

      let tempUnit = 'c'
      if (this.$store.state.userProfile?.settings.tempUnit === UnitOfMeasure.DEGREES_F) {
        tempUnit = 'f'
      }

      const pressureUnit = this.$store.state.userProfile?.settings.pressureUnit || UnitOfMeasure.INCH_MERCURY
      const pmUnit = this.$store.state.userProfile?.settings.pmUnit || UnitOfMeasure.MICROGRAMS_PER_CUBIC_METER
      return {
        id: id,
        sensor: sensorTypesKey[measurementKey],
        measurement: measurementTypesKey[measurementKey],
        tempUnit: tempUnit,
        pressureUnit,
        pmUnit,
        start: start.toISOString(),
        end: end.toISOString(),
      }
    },
    deviceHasHistoricalData(deviceId){
      return (this.historicalData.some(data => data.deviceId === deviceId))
    },
    getHistoricalTelemetryData(deviceId){
      return this.historicalData.filter(telemetry => telemetry.deviceId === deviceId).map(telemetry => telemetry.telemetryData)[0]
    },
    appendDataToHistoryChart(deviceId, reading){
     let deviceIndex = this.getDeviceIndex(deviceId);
     this.historicalData[deviceIndex].telemetryData = this.historicalData[deviceIndex].telemetryData.concat(reading)
    },
    removeOutdatedDataFromHistoryChart(deviceId){
      if (this.deviceHasHistoricalData(deviceId)) {
      let deviceIndex = this.getDeviceIndex(deviceId);
        for (let data of this.historicalData[deviceIndex].telemetryData) {
          if ((new Date(data[0]).toISOString() < new Date(this.getStartDate()).toISOString())){
            this.historicalData[deviceIndex].telemetryData = this.historicalData[deviceIndex].telemetryData.slice(1)
            continue
          }
          break
        }
      }
    },
    removeDeviceFromHistoricalData(deviceId){
      let deviceIndex = this.getDeviceIndex(deviceId);
      this.historicalData.splice(deviceIndex, 1)
    },
    getDeviceIndex(deviceId){
      return this.historicalData.findIndex((data => data.deviceId === deviceId))
    },
    getEndDate(){
      if(this.timePeriodControl.custom) return this.customDateRange.end
      return new Date()
    },
    getStartDate(){
      if (this.timePeriodControl.custom) return this.customDateRange.start
      let start = new Date()
      let age = 30
      if (this.timePeriodControl.day) age = 1
      if (this.timePeriodControl.week) age = 7
      if (this.timePeriodControl.month) age = 30
      if (this.timePeriodControl.threeMonths) age = 94
      return start.setDate(start.getDate() - age)
    },
    getSelectedDeviceIndex(id) {
      const index = this.selectedDevices.findIndex((selectedDevice) => {
        return selectedDevice.id === id
      })
      return index === -1 ? 0 : index
    },
    preprocess(arr) {
      const [tmpArr, gaps, intervalChanges] = findGaps(arr)
      const gapPoints = gaps.map((ts) => [ts, null])
      arr = [...tmpArr, ...gapPoints]
      arr.sort((ptA, ptB) => {return ptA[0] - ptB[0] })
      return [arr, intervalChanges]
    },
    async setGraphData(readings, id) {
      if (!readings?.length) {
        if (!this.isLoading) {
          this.graphDrawn = true
        }
      } else {
        const index = this.getSelectedDeviceIndex(id)
        const seriesColor = this.graphColors[index]
        let flags = {}
        let plotLines = []
        const measurements = [measurementTypes.pm25, measurementTypes.pm10]
        if (
          readings || measurements.indexOf(this.selectedParameter) !== -1) {
          flags = createFlags(readings, seriesColor)
          if (this.enableDataGaps) {
            const [arr] = this.preprocess(readings)
            readings = arr
          }
          plotLines = getPlotLines(readings, seriesColor)
        }

        if (this.graph) {
          this.graph.addSeries({
            data: readings,
            connectNulls: false,
            lineWidth: 0.5,
            name: this.selectedDevices[index].friendlyName,
            color: seriesColor,
          })

          this.graph.addSeries(flags)
          this.updatePlotLines(this.graphOptions.xAxis.plotLines.concat(plotLines))
          this.graph.redraw()
          this.graph.reflow()
          this.addEventListeners()
        }

        this.numShownDatasets++
      }
    },
    initGraph() {
      const units = this.getUnitsLabel(this.selectedParameter)
      this.graphOptions.tooltip.formatter = getTooltipFormatterCallback(
        units,
        this.precision
      )
      this.graphDrawn = false
      this.graphOptions.chart.events = {
        redraw: () => {
          if (!this.isLoading) {
            this.graphDrawn = true
            this.removeExistingEventListeners()
            this.addEventListeners()
          }
        },
      }
      this.graphOptions.xAxis.events = {
        afterSetExtremes: this.setExtremesHandler,
      }
      if (Highcharts) {
        const ref = this.$refs['graph']
        if (ref) {
          this.graph = Highcharts.chart(ref, this.graphOptions)
        }

        if (this.redrawGraph) {
          setTimeout(() => {
            this.changeTimePeriod(this.timePeriod)
          }, 400)
        }
      }
      this.graphInit = true
    },
    reflowGraph() {
      if (this.graph) {
        Vue.nextTick(() => {
          this.graph.reflow()
        })
      }
    },
    destroyGraph() {
      if (this.graph) {
        this.graph.destroy()
        this.graph = null
        this.graphInit = false
      }
    },
    applyGraphRange() {
      if (this.graph && (this.graphRange.min || this.graphRange.max)) {
        // https://api.highcharts.com/class-reference/Highcharts.Axis#setExtremes
        this.graph.xAxis[0].setExtremes(
          this.graphRange.min,
          this.graphRange.max,
          true,
          false
        )
      }
    },
    setCustomDateRange(range) {
      if (!range) return
      this.customDateRange = range
      this.changeTimePeriod(TIME_PERIOD_CUSTOM)
    },
    setGraphRange(extreme, value) {
      if (VALID_EXTREMES.includes(extreme)) {
        this.graphRange[extreme] = value
      }
    },
    clearGraphRange(extreme = null) {
      if (extreme && VALID_EXTREMES.includes(extreme)) {
        this.graphRange[extreme] = null
      } else {
        this.graphRange.min = null
        this.graphRange.max = null
      }
    },
    setExtremesHandler(event) {
      if (!this.isLoading) {
        if (event.userMin && event.userMin !== event.dataMin) {
          this.setGraphRange('min', event.userMin)
        } else {
          this.clearGraphRange('min')
        }
        if (event.userMax && event.userMax !== event.dataMax) {
          this.setGraphRange('max', event.userMax)
        } else {
          this.clearGraphRange('max')
        }
      }
    },
    showCustomTooltip(event) {
      if (event) {
        if (this.graph.customTooltip) return

        const { x, y } = getCustomTooltipPosition(event, this.graph, 245)
        this.graph.customTooltip = renderNoTimePeriodTooltip(this.graph, x, y)
      }
    },
    hideCustomTooltip() {
      this.graph.customTooltip?.destroy()
      this.graph.customTooltip = null
    },
    addEventListeners() {
      if (this.graph?.xAxis?.[0]?.plotLinesAndBands?.length) {
        this.graph.xAxis[0].plotLinesAndBands.forEach((plotLine) => {
          // Only add event listeners if the plotLine has a label (which would be the first plotLine)
          if (plotLine.label?.element) {
            const labelElement = plotLine.label.element

            // Store reference to event listeners for removal
            this.eventListeners.push({
              element: labelElement,
              event: 'mouseover',
              handler: this.showCustomTooltip,
            })
            this.eventListeners.push({
              element: labelElement,
              event: 'mouseout',
              handler: this.hideCustomTooltip,
            })
            labelElement.addEventListener(
              'mouseover',
              this.showCustomTooltip
            )
            labelElement.addEventListener('mouseout', this.hideCustomTooltip)
          }
        })
      }
    },
    removeExistingEventListeners() {
      this.eventListeners.forEach(({ element, event, handler }) => {
        element.removeEventListener(event, handler)
      })
      this.eventListeners = []
    },
    indoorIconSrc(model) {
      return this.$store.getters['devicemodels/getPinPath'](model, 'smallIndoor')
    },
    outdoorIconSrc(model) {
      return this.$store.getters['devicemodels/getPinPath'](model, 'smallOutdoor')
    },
    getPermissionTooltip() {
      if (this.displayNonPublicDevice || this.displayMyDevice) {
        return this.$t('map.historyDialog.upgradeTooltip')
      } else {
        return this.$t('map.historyDialog.publicDeviceTimespanMessage')
      }
    },
    getDeviceTooltip(device) {
      let message = device.friendlyName
      if (!this.isMeasurementSupported(device)) {
        return this.$t('map.historyDialog.notSupported', {
          measurement: this.graphLabel,
        })
      } else if (!this.isMeasurementAllowed(device)) {
        return this.$t('map.historyDialog.notAllowed')
      } else if (!this.isTimePeriodAllowed(device)) {
        return this.$t('map.historyDialog.timePeriodNotAllowed')
      }
      return message
    },
    getUnitsLabel(param) {
      switch(param) {
        case measurementTypes.temp: {
          const tempUnit = this.$store.state.userProfile?.settings.tempUnit
          return UnitOfMeasureProperties[tempUnit].code || this.$t('units.celsius')
        } case measurementTypes.baro_inhg: {  // pressure
          const pressureUnit = this.$store.state.userProfile?.settings.pressureUnit
          return UnitOfMeasureProperties[pressureUnit].code || this.$t('units.inHg')
        } case measurementTypes.pm10_raw:
          case measurementTypes.pm25_raw: {
          const pmUnit = this.$store.state.userProfile?.settings.pmUnit
          return UnitOfMeasureProperties[pmUnit].code || this.$t('units.ugm3')
        } default:
          return measurementTypesUnitsKey[param]
            ? this.$t(measurementTypesUnitsKey[param])
            : ''
      }
    },
    getButtonColor(period) {
      return this.timePeriodControl[period] ? 'primary' : 'white'
    },
    isDeviceActive(device) {
      return (
        this.isMeasurementAllowedAndSupported(device) &&
        this.isTimePeriodAllowed(device)
      )
    },
    disableDeviceTooltip(device) {
      return (
        !isChildOverflowing(this.$refs[device.id]) &&
        this.isDeviceActive(device)
      )
    },
    getCardElsHeight() {
      // combined height of non-graph elements
      const title = this.isDetachedDialog
        ? DIALOG_TITLE_HEIGHT
        : WIDGET_TITLE_HEIGHT
      const tpc = this.$refs.timePeriodControl
        ? this.$refs.timePeriodControl.clientHeight
        : DEFAULT_ELEMENT_HEIGHT
      const hgl = this.$refs.historyGraphLabel
        ? this.$refs.historyGraphLabel.clientHeight
        : DEFAULT_ELEMENT_HEIGHT
      const sd = this.$refs.selectedDevices
        ? this.$refs.selectedDevices.clientHeight
        : DEFAULT_ELEMENT_HEIGHT
      const mmm = this.$refs.maximumMetMessage
        ? this.$refs.maximumMetMessage.clientHeight
        : DEFAULT_ELEMENT_HEIGHT
      return title + tpc + hgl + sd + mmm
    },
    setGraphHeight() {
      let graphHeight = GRAPH_HEIGHT_MEDIUM
      const cardElsHeight = this.getCardElsHeight()

      if (this.isFullscreen) {
        graphHeight = GRAPH_HEIGHT_FULLSCREEN
      } else if (this.isDetachedDialog) {
        graphHeight = GRAPH_HEIGHT_SMALL
      } else if (this.height) {
        graphHeight = `${this.height - cardElsHeight}px`
      }

      this.graphHeight = graphHeight
    },
    getIndexInSelectedDevices(deviceId) {
      return this.selectedDevices.findIndex((device) => device.id === deviceId)
    },
    loadDataHandler() {
      this.loadDataQueue.push(this.loadData)
    },
    cleanHistoricalData() {
      this.historicalData = []
      this.clearPlotLines()
    },
    updatePlotLines(plotLines) {
      if (this.graph && typeof this.graph.update === 'function' && plotLines) {
        this.graphOptions.xAxis.plotLines = plotLines
        this.graph.update(
          {
            xAxis: {
              plotLines: plotLines,
            },
          },
          true
        )
      }
    },
    clearPlotLines() {
      this.updatePlotLines([])
    },
  },
}
</script>

<style lang="scss">
@media (min-width: 400px) {
  .history-dialog {
    position: absolute;
    right: 10px;
    top: 70px;
  }
}
</style>

<style lang="scss" scoped>
.restrictedContent {
  cursor: not-allowed;
  opacity: 0.36;
}
.v-card {
  padding: 0 !important;
}

.v-card__actions {
  .timePeriodControl {
    background-color: #ececec;
    padding: 6px 16px 6px 16px;
    width: calc(100% + 12px);
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    margin-left: -6px;
    margin-right: -6px;
    &__label {
      font-size: 13px;
      line-height: 21px;
    }
    &__controls {
      white-space: nowrap;
      margin-left: 4px;
    }
    .v-btn {
      margin-left: 8px;
      padding: 0 10px;
      height: 24px;
      font-size: 11px;
    }
  }
}
.card-content {
  padding: 0 0.4rem 0.4rem 0.4rem;
  .v-card__subtitle {
    padding: 8px 8px 8px 16px;
  }
  .selected-devices {
    margin: 0;
    padding: 4px;
    div {
      max-width: calc(100% - 8px);
      margin: 8px 4px 8px 8px;
      font-size: 12px;
      transition: opacity 0.2s;
      &.inactive {
        opacity: 0.5;
      }
      .selected-device-icon {
        margin-right: 6px;
        height: 14px;
      }
      .device-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .v-icon {
        cursor: pointer;
        font-size: 18px;
      }
    }
  }
  .maximum-met-message {
    font-size: 12px;
    color: grey;
    padding: 0 8px 8px;
  }
}

.text-dark {
  color: black;
}

.opacity-20 {
  opacity: 0.2;
}

.loading-container {
  position: absolute;
  top: 200px;
  left: calc(50% - 16px);
  opacity: 1;
}
.loading-container-fullscreen {
  top: 350px;
}
.empty-message {
  position: absolute;
  top: 200px;
  width: 98%;
  text-align: center;
  opacity: 1;
  color: rgba(0, 0, 0, 0.6);
}
.empty-message-fullscreen {
  top: 350px;
}
</style>
